<template>
    <div>
        <el-upload
            action=""
            multiple
            class="upload-demo"
            drag
            :file-list="fileList"
            list-type="picture"
            :http-request="httpRequest"
            :on-success="onSuccess"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>

        <div ref="canvasList"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fileList: [],
            canvasList: [],
            allList: [
                '兽人001_11_33_00003.png',
                '兽人001_11_33_00004.png',
                '兽人001_11_33_00005.png',
                '兽人001_1_33_00001.png',
                '兽人001_1_33_00002.png',
                '兽人001_11_33_00006.png',
                '兽人001_11_33_00007.png',
                '兽人001_11_33_00008.png',
                '兽人001_11_33_00009.png',
                '兽人001_111_33_00010.png',
                '兽人001_111_33_00011.png',
                '兽人001_111_33_00012.png',
                '兽人001_111_33_00013.png',
                '兽人001_111_33_00014.png',
                '兽人001_111_33_00015.png',
                '兽人001_111_33_00016.png',
                '兽人001_1111_33_00017.png',
                '兽人001_1111_33_00018.png',
                '兽人001_1111_33_00019.png',
                '兽人001_1111_33_00020.png',
                '兽人001_1111_33_00021.png'
            ]
        };
    },
    computed: {
        fileNames() {
            return [...this.fileList].map(item => {
                return item.name;
            });
        }
    },
    watch: {
        fileList() {}
    },
    mounted() {
        this.filterContent();
    },
    methods: {
        filterContent() {
            let list = [...this.allList].map(item => {
                return this.filterByName(item);
            });
            console.log(list);
            let backMap = new Map();
            list.forEach(item => {
                let productionMap = this.getMap(backMap, item.productionID);
                let layers = this.getMap(productionMap, Number(item.layerID), 'List');
                layers.push(item);
                productionMap.set(Number(item.layerID), layers);
                backMap.set(item.productionID, productionMap);
            });
            [...backMap.values()].forEach(_map => {
                let _list = [..._map.keys()]
                    .sort((a, b) => {
                        return a - b;
                    })
                    .map(_key => {
                        return _map.get(_key).map(_a => {
                            return _a.name;
                        });
                    });
                console.log(_list);
                console.log(this.doExchange(_list));
            });
        },
        doExchange(arr) {
            var len = arr.length;
            // 当数组大于等于2个的时候
            if (len >= 2) {
                // 第一个数组的长度
                var len1 = arr[0].length;
                // 第二个数组的长度
                var len2 = arr[1].length;
                // 2个数组产生的组合数
                var lenBoth = len1 * len2;
                //  申明一个新数组,做数据暂存
                var items = new Array(lenBoth);
                // 申明新数组的索引
                var index = 0;
                // 2层嵌套循环,将组合放到新数组中
                for (var i = 0; i < len1; i++) {
                    for (var j = 0; j < len2; j++) {
                        items[index] = arr[0][i] + '|' + arr[1][j];
                        index++;
                    }
                }
                // 将新组合的数组并到原数组中
                var newArr = new Array(len - 1);
                for (var i = 2; i < arr.length; i++) {
                    newArr[i - 1] = arr[i];
                }
                newArr[0] = items;
                // 执行回调
                return this.doExchange(newArr);
            } else {
                return arr[0];
            }
        },
        getMap(parentMap, key, type = 'Map') {
            let backMap = type == 'Map' ? new Map() : [];
            if (parentMap.has(key)) {
                backMap = parentMap.get(key);
            }
            return backMap;
        },
        filterByName(info) {
            let names = info.split('_');
            return {
                productionID: names[0],
                layerID: names[1],
                probability: names[2],
                id: names[3],
                name: info
            };
        },
        httpRequest(e) {
            return Promise.resolve('上传成功');
        },
        onSuccess(response, file, fileList) {
            let names = [...this.fileNames];
            let list = [...this.fileList];
            if (!names.includes(file.name)) {
                list.push(file);
            }

            this.fileList = list;
        }
    }
};
</script>

<style lang="less" scoped></style>
